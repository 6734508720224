import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from "react-i18next";

import API_CONSTANT from '../../constants/api';
import AuthHeader from './authHeader';
import {
  saveAccessToken,
  saveUser,
  logoutUser,
  saveOrgDetails,
  setFirstLogin,
  getFirstLogin
} from '../../utils/sessionManager';
import { DynamicRoutes } from '../../constants/routes';
import { appService } from '../../services/appService';
import { getValue } from '../../utils/object';

export default function Login() {
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const translation = t("login");

  const [formData, setFormData] = useState({ lastName: '', roomNumber: '' });

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFirstLogin = () => {
    const firstLogin = getFirstLogin();
    if (firstLogin) setFirstLogin('No');
    else setFirstLogin('Yes');
  };

  const loginRequest = (payload) => {
    axios
      .post(`${API_CONSTANT.GUEST_LOGIN}`, payload, {
        headers: { username: appService.getTenantId(), 'Content-Type': 'application/json' },
      })
      .then((res) => {
        const { data } = res;
        if (data.userInfo && data.accessToken) {
          handleFirstLogin();
          if (data.org_details) saveOrgDetails(data.org_details);
          saveUser(data.userInfo);
          saveAccessToken(data.accessToken);
          window.location.replace(DynamicRoutes.ClientRoot);
          return;
        }
        addToast('Sorry, Did not match any bookings!', {
          autoDismiss: true,
          appearance: 'error',
        });
      })
      .catch((err) => {
        console.log('ERROR:', err);
        addToast('Please type correct room number!', {
          autoDismiss: true,
          appearance: 'error',
        });
      });
  };

  useEffect(() => {
    logoutUser();
  }, []);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    loginRequest(JSON.stringify(formData));
  };

  return (
    <>
      <AuthHeader />
      <section className="login_area">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-10 offset-md-1">
              <div className="login_box">
                <h1>{getValue(translation, 'title')}</h1>
                <form onSubmit={handleFormSubmit}>
                  <div className="sin_input ha_login_input">
                    <p>{getValue(translation, 'form.lastName')}</p>
                    <label />
                    <input
                      style={{ color: '#222' }}
                      onChange={handleInputChange}
                      value={formData.lastName}
                      name="lastName"
                      type="text"
                      placeholder={getValue(translation, 'form.lastNamePlaceHolder')}
                      required
                    />
                  </div>
                  <div className="sin_input ha_login_input">
                    <p>{getValue(translation, 'form.roomNumber')}</p>
                    <label />
                    <input
                      style={{ color: '#222' }}
                      onChange={handleInputChange}
                      value={formData.roomNumber}
                      name="roomNumber"
                      type="text"
                      placeholder={getValue(translation, 'form.roomNumberPlaceHolder')}
                      required
                    />
                  </div>
                  <div className="ha_button login_button">
                    <input type="submit" value={getValue(translation, 'loginBtn')} readOnly />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
