export const getSelectedLanguage = ()=>{
  const localStorageLang =  localStorage.getItem('avendi-lang');
  const selectedLang = (localStorageLang && localStorageLang !== null) ? JSON.parse(localStorageLang)?.code : "en";
  return selectedLang
}

export const getLanguageTranslationStatus = ()=>{
  const storedValue = sessionStorage.getItem("langTranslationStatus");
  if (storedValue === 'true') return true;
  return false
}