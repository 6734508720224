import React, { useState, useCallback, useEffect } from 'react';
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useParams,
  useLocation,
} from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import ReactGA from 'react-ga4';

import FAQs from './faq';
import FaqChat from './faq/faq';
import FoodMenu from './foodMenu';
import NotFoundPage from '../404';
import AuthHome from './auth/home';
import LoginPage from './auth/login';
import TravelDesk from './travelDesk';
import Services from './servicesMenu';
import RequestItem from './requestItem';
import IssueReport from './issueReport';
import FoodDetails from './foodMenu/details';
import DashboardHome from './dashboard/home';
import CheckoutFood from './foodMenu/checkout';
import TravelDeskDetails from './travelDesk/details';
import { RequestStatusDetailsPage } from './dashboard/requestStatusDetails';

// import MonaBannerLogo from '../assets/images/img/mona-banner-logo-dark.png';

import LoadingScreen from '../LoadingScreen';
import CartLayout from './layouts/CartLayout';
import MainLayout from './layouts/MainLayout';
import BlankLayout from './layouts/BlankLayout';
import AppLayoutWrapper from './layouts/AppLayoutWrapper';
import BlankLayoutWithLogo from './layouts/BlankLayoutWithLogo';

import { appService } from '../services/appService';
import { getUser, getAccessToken } from '../utils/sessionManager';
import { DynamicRoutes, AnonymousRoutes, Routes } from '../constants/routes';

import { InternalServices, BookInternalService } from './InternalServices';
// import {
//   MuseumMenu,
//   ExploreMuseum,
//   BookATour as BookAMuseumTour,
//   MuseumItemDetails,
//   MuseumPackages,
//   MuseumPackageDetails,
// } from "./museum";
import Feedback from './feedback/Feedback';
import Profile from './profile/Profile';
import ViewAllProperties from './profile/ViewAllProperties';
import ViewAllDineAndDrinks from './profile/ViewAllDineAndDrinks';
import Spa from './profile/Spa';
import Fitness from './profile/Fitness';
import OurServices from './profile/OurServices';

const Redirect404 = () => <Redirect to={Routes.NotFound} />;

const TenantRoute = () => {
  /* Set html title & favicon for the tenant */
  const [isVerifying, setIsVerifying] = useState(true);
  const [tenantData, setTenantData] = useState(null);
  const location = useLocation();

  const user = getUser();
  const token = getAccessToken();
  const isExpired = token ? jwtDecode(token).exp < Date.now() / 1000 : true;
  const existingTenant = token ? jwtDecode(token).data?.username : null;
  const { tenantId } = useParams();

  const verifyTenant = useCallback(async () => {
    setIsVerifying(true);
    const { data } = await appService.verifyTenant(tenantId);
    if (data) {
      appService.setTenantId(tenantId).setTenantData(data);
    }

    setTenantData(data);
    setIsVerifying(false);
  }, [tenantId]);

  useEffect(() => {
    if (process.env.REACT_APP_ENV !== 'production') return;

    if (!tenantId) return;

    const trackPageView = () => {
      const page = location.pathname;
      let title = '';

      if (page.includes('login')) {
        title = `${tenantId} - Login`;
      } else if (page.includes('home')) {
        title = `${tenantId} - Home`;
      } else {
        return; // Do not track other pages
      }

      ReactGA.send({
        hitType: 'pageview',
        page,
        title,
      });
    };

    trackPageView();
  }, [location.pathname, tenantId]);

  useEffect(() => {
    verifyTenant();
  }, [verifyTenant]);

  if (isVerifying) {
    return <LoadingScreen />;
  }

  if (!tenantId || !tenantData || !appService.getTenantId()) {
    return <Redirect to={Routes.NotFound} />;
  }
  const { clientSettings } = tenantData;

  const additionalProps = {
    tenantId: tenantId,
  };

  return (
    <Switch>
      <Route exact path={DynamicRoutes.Login} component={LoginPage} />
      <Route
        exact
        path={DynamicRoutes.Home}
        render={(props) => <AuthHome {...props} {...additionalProps} />}
      />
      {/* For some reason, defining all routes together with a single condition doesn't work */}

      <AppLayoutWrapper
        exact
        path={DynamicRoutes.Profile}
        layout={BlankLayoutWithLogo}
        component={Profile}
      />
      <AppLayoutWrapper
        exact
        path={DynamicRoutes.ViewallProperties}
        layout={BlankLayoutWithLogo}
        component={ViewAllProperties}
      />
      <AppLayoutWrapper
        exact
        path={DynamicRoutes.ViewallDineAndDrinks}
        layout={BlankLayoutWithLogo}
        component={ViewAllDineAndDrinks}
      />
      <AppLayoutWrapper
        exact
        path={DynamicRoutes.Spa}
        layout={BlankLayoutWithLogo}
        component={Spa}
      />
      <AppLayoutWrapper
        exact
        path={DynamicRoutes.Fitness}
        layout={BlankLayoutWithLogo}
        component={Fitness}
      />
      <AppLayoutWrapper
        exact
        path={DynamicRoutes.ServiceCategory}
        layout={BlankLayoutWithLogo}
        component={OurServices}
      />
      <AppLayoutWrapper
        exact
        path={DynamicRoutes.Checkout}
        layout={CartLayout}
        component={CheckoutFood}
      />
      {/* {clientSettings.enableMuseumModule && ( */}
      {/* <AppLayoutWrapper
        exact
        path={DynamicRoutes.Museum}
        layout={BlankLayoutWithLogo}
        component={MuseumMenu}
        logo={MonaBannerLogo}
      /> */}

      {/* {clientSettings.enableMuseumModule && ( */}
      {/* <AppLayoutWrapper
        exact
        path={DynamicRoutes.MuseumTourBook}
        layout={BlankLayoutWithLogo}
        component={BookAMuseumTour}
        logo={MonaBannerLogo}
      /> */}

      {/* {clientSettings.enableMuseumModule && ( */}
      {/* <AppLayoutWrapper
        exact
        path={DynamicRoutes.MuseumExplore}
        layout={BlankLayoutWithLogo}
        component={ExploreMuseum}
        logo={MonaBannerLogo}
      /> */}
      {/* )} */}
      {/* <AppLayoutWrapper
        exact
        path={DynamicRoutes.MuseumItemDetails}
        layout={BlankLayoutWithLogo}
        component={MuseumItemDetails}
        logo={MonaBannerLogo}
      />
      <AppLayoutWrapper
        exact
        path={DynamicRoutes.MuseumPackages}
        layout={BlankLayoutWithLogo}
        component={MuseumPackages}
        logo={MonaBannerLogo}
      />
      <AppLayoutWrapper
        exact
        path={DynamicRoutes.MuseumPackageDetails}
        layout={BlankLayoutWithLogo}
        component={MuseumPackageDetails}
        logo={MonaBannerLogo}
      /> */}

      {!user ||
      !token ||
      !!isExpired ||
      existingTenant !== appService.getTenantId() ? (
        <Redirect to={DynamicRoutes.Home} />
      ) : (
        <>
          <AppLayoutWrapper
            exact
            path={DynamicRoutes.ClientRoot}
            layout={MainLayout}
            component={DashboardHome}
          />
          <AppLayoutWrapper
            exact
            path={DynamicRoutes.MyRequestDetails}
            layout={BlankLayoutWithLogo}
            component={RequestStatusDetailsPage}
          />
          <AppLayoutWrapper
            exact
            path={DynamicRoutes.Services}
            layout={BlankLayoutWithLogo}
            component={Services}
          />
          <AppLayoutWrapper
            exact
            path={DynamicRoutes.Feedback}
            layout={MainLayout}
            component={Feedback}
          />

          {clientSettings.enableFaq && !!clientSettings.faqAgentId && (
            <AppLayoutWrapper
              exact
              path={DynamicRoutes.Faq}
              layout={BlankLayout}
              component={FAQs}
            />
          )}
          <AppLayoutWrapper
            exact
            path={DynamicRoutes.Chat}
            layout={BlankLayout}
            component={FaqChat}
          />
          <AppLayoutWrapper
            exact
            path={DynamicRoutes.FoodMenu}
            layout={CartLayout}
            component={FoodMenu}
          />
          <AppLayoutWrapper
            exact
            path={DynamicRoutes.FoodMenuDetails}
            layout={CartLayout}
            component={FoodDetails}
          />
          <AppLayoutWrapper
            exact
            path={DynamicRoutes.IssueReport}
            layout={CartLayout}
            component={IssueReport}
          />
          <AppLayoutWrapper
            exact
            path={DynamicRoutes.RequestItem}
            layout={CartLayout}
            component={RequestItem}
          />
          <AppLayoutWrapper
            exact
            path={DynamicRoutes.TravelDesk}
            layout={CartLayout}
            component={TravelDesk}
          />
          <AppLayoutWrapper
            exact
            path={DynamicRoutes.TravelDeskDetails}
            layout={CartLayout}
            component={TravelDeskDetails}
          />
          {/* {clientSettings.enableInternalServices && ( */}
          <AppLayoutWrapper
            exact
            path={DynamicRoutes.InternalServices}
            layout={BlankLayoutWithLogo}
            component={InternalServices}
          />

          {/* {clientSettings.enableInternalServices && ( */}
          <AppLayoutWrapper
            exact
            path={DynamicRoutes.BookInternalService}
            layout={BlankLayoutWithLogo}
            component={BookInternalService}
          />
          {/* )} */}
          <Route path="*">
            <Redirect to={DynamicRoutes.ClientRoot} />
          </Route>
        </>
      )}
    </Switch>
  );
};

const AppRouter = () => {
  const history = useHistory();
  const location = useLocation();
  appService.setHistory(history);

  if (AnonymousRoutes.includes(location.pathname)) {
    return (
      <Switch>
        <Route exact path={Routes.NotFound} component={NotFoundPage} />
      </Switch>
    );
  }

  // To change the bg color of internal services
  if (location.pathname === DynamicRoutes?.InternalServices) {
    document.body.style.backgroundColor = 'white';
  } else if (location.hash == '' && location.pathname.endsWith('book')) {
    document.body.style.backgroundColor = 'white';
  } else {
    document.body.style.backgroundColor = '#f5f5f5';
  }
  return (
    <Switch>
      <Route exact path={Routes.ClientRoot} component={TenantRoute} />
      <Route path={Routes.App} component={TenantRoute} />
      <Route path="*" component={Redirect404} />
    </Switch>
  );
};

export default AppRouter;
export { AppRouter };
