import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from "react-i18next";

import MapsAndParkingImg from '../../assets/images/icon/map.svg';
import BellBoyImg from '../../assets/images/icon/bellboy.svg';
import Feedback from '../../assets/images/icon/feedback.svg';
import Market from '../../assets/images/icon/marketplace.svg';
import InRoomDiningImg from '../../assets/images/icon/in-room-dining.svg';
import InternalServiceImg from '../../assets/images/icon/wind-rose.svg';
import TravelDeskImage from '../../assets/images/icon/travel-desk.svg';
import MonaImage from '../../assets/images/img/mona.png';

import MyStatus from './myStatus';
import { NavigationCard } from '../../components/NavigationCard';
import { HotelServiceContext } from '../../contexts/HotelServiceContext';

import { appService } from '../../services/appService';
import { DynamicRoutes } from '../../constants/routes';
import { listModule } from '../../services/manageModuleService';
import useAnalyticsEventTracker from '../../hooks/analytics.hooks';
import { getValue } from '../../utils/object';

export const HomeTabs = Object.freeze({
  Home: 'Home',
  Status: 'Status',
});

export default function HomeNavigators({ activeMenu }) {
  const { requestStatus, myRequestStatus } = useContext(HotelServiceContext);
  const gaEventTracker = useAnalyticsEventTracker();
  const { t } = useTranslation();
  const translation = t("home");


  const [module, setModule] = useState([]);
  const tenantData = appService.getTenantData();
  const { clientSettings } = tenantData;
  const fetchMyRequestStatus = () => {
    myRequestStatus().then().catch();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchMyRequestStatus, []);
  useEffect(() => {
    listModule(setModule);
  }, []);

  const getModuleStatus = (modules, moduleName) =>
    modules
      ?.filter((item) => item.modulesName === moduleName)
      .map((item) => item.isActive);

  const feedback = getModuleStatus(module, 'feedback');
  const hotelService = getModuleStatus(module, 'hotelService');
  const inRoomDiningService = getModuleStatus(module, 'inRoomDiningService');
  const experiences = getModuleStatus(module, 'experiences');
  // const museum = getModuleStatus(module, "museum");
  const marketplace = getModuleStatus(module, 'marketplace');
  const directives = getModuleStatus(module, 'directives');

  const onGiftSouvenirsClick = () => {
    gaEventTracker({
      eventName: 'Local_Avendi_Hotel_Home_Redirects',
      category: 'User',
      action: `Local Avendi Redirects`,
      label: `${tenantData?.username} - Local Avendi Home Redirects`,
    });
  };

  switch (activeMenu) {
    case HomeTabs.Home:
    default: {
      return (
        <>
          <NavigationCard
            label={getValue(translation, 'hotelDirectory.title')}
            link={appService.getFullPath(DynamicRoutes.Profile)}
            horizontal={true}
            hidden={!directives[0]}
            // css="px-1"
            description={getValue(translation, 'hotelDirectory.description')}
          />
          {/* <NavigationCard
            image={MapsAndParkingImg}
            label="Profile"
            link={DynamicRoutes.Profile}
            hidden={!directives[0]}
            // hidden={clientSettings.disableHotelServices}
          /> */}
          <NavigationCard
            image={BellBoyImg}
            label={getValue(translation, 'hotelServices.title')}
            link={DynamicRoutes.Services}
            hidden={!hotelService[0]}
            description={getValue(translation, 'hotelServices.description')}
            // hidden={clientSettings.disableHotelServices}
          />
          <NavigationCard
            image={InRoomDiningImg}
            label={getValue(translation, 'inRoomDining.title')}
            link={DynamicRoutes.FoodMenu}
            hidden={!inRoomDiningService[0]}
            description={getValue(translation, 'inRoomDining.description')}
            // hidden={clientSettings.disableInRoomDining}
          />
          <NavigationCard
            image={InternalServiceImg}
            label={getValue(translation, 'experiences.title')}
            link={DynamicRoutes.InternalServices}
            hidden={!experiences[0]}
            description={getValue(translation, 'experiences.description')}
            // hidden={!clientSettings.enableInternalServices}
          />
          <NavigationCard
            image={TravelDeskImage}
            label="Travel Desk"
            link={DynamicRoutes.TravelDesk}
            hidden
          />
          {/* <NavigationCard
            image={MonaImage}
            label="Museum"
            link={DynamicRoutes.Museum}
            hidden={!museum[0]}
            description="Explore art work by generations of Nepali artists."
            hidden={!clientSettings.enableMuseumModule}
          /> */}
          <NavigationCard
            label="Frequently Asked Questions"
            link={DynamicRoutes.Faq}
            horizontal
            fixedToBottomOnMobile
            hidden={!clientSettings.enableFaq || !clientSettings.faqAgentId}
          />
          <NavigationCard
            image={Feedback}
            label={getValue(translation, 'feedback.title')}
            link={DynamicRoutes.Feedback}
            hidden={!feedback[0]}
            description={getValue(translation, 'feedback.description')}
            // hidden={clientSettings.disableHotelServices}
          />
          <NavigationCard
            image={Market}
            label={getValue(translation, 'souvenirs.title')}
            link="https://np-local.avendi.me/"
            hidden={!marketplace[0]}
            description={getValue(translation, 'souvenirs.description')}
            onClick={onGiftSouvenirsClick}
            // hidden={clientSettings.disableHotelServices}
          />
        </>
      );
    }

    case HomeTabs.Status: {
      return <MyStatus data={requestStatus} />;
    }
  }
}
