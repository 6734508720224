import React from 'react';
import { Route } from 'react-router-dom';

const LayoutWrapper = ({ component: Component, layout: Layout, logo, showFooter, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout logo={logo} showFooter={showFooter}>
        <Component {...props} />
      </Layout>
    )}
  />
);

export default LayoutWrapper;
