import React from 'react';
import './NavigationCard.scss';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import ArrowImg from '../../assets/images/icon/arrow.png';

export const NavigationCard = (props = {}) => {
  const {
    image,
    label,
    link,
    css,
    showArrow = true,
    classic = false,
    showBackground = true,
    overrideBackgroundImage,
    hidden = false,
    horizontal = false,
    description,
    fixedToBottomOnMobile = false,
    onClick = () => {},
  } = props;

  if (hidden) {
    return null;
  }

  if (classic) {
    return (
      <div className="col-6">
        <Link to={link}>
          <div className="single_tabs_items">
            <div className="tabs_items_img">
              <img src={image} alt={label} />
              <h4>{label}</h4>
            </div>
            <div className="tabs_items_icon">
              <span>
                <img src={ArrowImg} alt="arrow" />
              </span>
            </div>
          </div>
        </Link>
      </div>
    );
  }

  if (horizontal) {
    return (
      <div
        className={classNames(`col-12 navigation-card y-stacked ${css}`, {
          'xs-position-fixed-bottom': fixedToBottomOnMobile,
        })}
      >
        <Link className="navigation-card__container" to={link}>
          <span className="link-container">
            <div>
              <p>{label}</p>
              <h5>{description}</h5>
            </div>
            <span className="social_arrow">
              <img src={ArrowImg} alt="arrow" />
            </span>
          </span>
        </Link>
      </div>
    );
  }

  return (
    <div className="col-6 navigation-card xy-stacked">
      <div className="navigation-card__container">
        {label === 'Buy Gifts & Souvenirs' ? (
          <a href={link} onClick={onClick} target="_blank">
            <span className="link-container">
              <img className="main-image" src={image} alt={label} />

              <p>{label}</p>
              <h5>{description}</h5>

              <span className="social_arrow">
                <img src={ArrowImg} alt="arrow" />
              </span>
            </span>
            <div
              className="navigation-card__background"
              style={{
                background: `url(${image})`,
              }}
            />
          </a>
        ) : (
          <Link to={link}>
            <span className="link-container">
              <img className="main-image" src={image} alt={label} />

              <p>{label}</p>
              <h5>{description}</h5>

              <span className="social_arrow">
                <img src={ArrowImg} alt="arrow" />
              </span>
            </span>
            <div
              className="navigation-card__background"
              style={{
                background: `url(${image})`,
              }}
            />
          </Link>
        )}
      </div>
    </div>
  );
};

export default NavigationCard;
