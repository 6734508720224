import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import HomeNavigators from "./homeNavigators";
import { getFirstLogin, setFirstLogin } from "../../utils/sessionManager";
import { useTranslation } from "react-i18next";
import { getValue } from "../../utils/object";

const HOME_TAB = "#home";
const STATUS_TAB = "#my_status";

export default function Main() {
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState(
    location.hash === STATUS_TAB ? "Status" : "Home"
  );

  const { t } = useTranslation();

  const gTranslation = t("global");

  const handleMenuClick = (menuItem) => {
    setActiveMenu(menuItem);
  };

  useEffect(() => {
    const isFirstLogin = getFirstLogin();
    if (isFirstLogin === "Yes") {
      console.log("Coaching starts here!");
      setFirstLogin("No");
    }
  }, []);

  useEffect(() => {
    if (![HOME_TAB, STATUS_TAB].includes(window.location.hash)) {
      window.location.href += activeMenu === "Status" ? STATUS_TAB : HOME_TAB;
    }
  }, [activeMenu]);

  return (
    <>
      <section className="mtabs_items_area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3 col-md-10 offset-md-1">
              <div id="mtabs">
                <div className="tabs_menu mb-3 mb-md-4">
                  <ul className="d-flex align-items-center">
                    <li
                      onClick={() => handleMenuClick("Home")}
                      className={`${
                        activeMenu === "Home"
                          ? "ui-tabs-active ui-state-active"
                          : ""
                      }`}
                    >
                      <Link to={HOME_TAB}>
                        {getValue(gTranslation, "home")}
                      </Link>
                    </li>
                    <li
                      onClick={() => handleMenuClick("Status")}
                      className={`${
                        activeMenu === "Status"
                          ? "ui-tabs-active ui-state-active"
                          : ""
                      }`}
                    >
                      <Link to={STATUS_TAB}>
                        {getValue(gTranslation, "status")}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="all_tabs_items">
                  <div>
                    <div className="row px-2">
                      <HomeNavigators activeMenu={activeMenu} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
